import React from "react";
import "../Data_adding_page/adminPage.css";
import * as XLSX from "xlsx/xlsx.mjs";
import { useState } from "react";
import axios from "axios";
import FlipkartC from "../Sql_Template/FC.json"
import FlipkartFF from "../Sql_Template/FFF.json"
import FlipkartRSF from "../Sql_Template/FRSF.json"
import FlipkartSF from "../Sql_Template/FSF.json"
import MyntraC from "../Sql_Template/MC.json"
import MyntraFF from "../Sql_Template/MFF.json"
import MyntraFSF from "../Sql_Template/MFSF.json"
import MyntraRSF from "../Sql_Template/MRSF.json"


const AdminPage = () => {
  const [fileName, setFileName] = useState("file not selected");
  const [file, setFile] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  console.log(selectedValue);
  console.log(file);

  const templateDownload = () => {
    const template = (selectedValue == "brandfortunes.Flipkart2_commision" ? FlipkartC : selectedValue == "brandfortunes.Flipkart2_fixedFees" ? FlipkartFF:
    selectedValue == "brandfortunes.Flipkart2_ReverseShippingFees" ? FlipkartRSF : selectedValue == "brandfortunes.Flipkart2_ShippingFees" ? FlipkartSF:
    selectedValue == "brandfortunes.Myntra2_Commission" ? MyntraC :selectedValue == "brandfortunes.Myntra2_Fixedfees" ? MyntraFF:
    selectedValue == "brandfortunes.Myntra2_ForwardShippingCharges" ? MyntraFSF:selectedValue == "brandfortunes.Myntra2_ReverseShippingCharges" ? MyntraRSF:null)
    const worksheet = XLSX.utils.json_to_sheet(template);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "sample-file.xlsx");
  };


  // const select = (e) => {
  //   const reader = new FileReader();
  //   reader.readAsBinaryString(e.target.files[0]);
  //   // console.log(e.target.files)
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     // console.log(workbook)
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const parsedData = XLSX.utils.sheet_to_json(sheet);
  //     console.log(parsedData);
  //   };
  // };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    console.log(file);
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      console.log(parsedData);
      setFile(parsedData);
    };
  };

  async function handleUpload() {
    try {
      const response = await axios.post("/upload", {
        data: file,
        ref: selectedValue,
      });
      console.log(file);
      console.log(selectedValue);
      console.log(response.data.sqlMessage) ||
        console.log(response);
    } catch (error) {
      console.error(error.response.data.sqlMessage);
    }
  }

  async function handleUpload1() {
    try {
      const response = await axios.post("/upload1", {
        data: file,
        ref: selectedValue,
      });
      console.log(file);
      console.log(selectedValue);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div class="container1">
      <div class="card">
        <h3>Upload Files</h3>
        <label for="pet-select">Choose:</label>

        <select
          name="pets"
          id="pet-select"
          value={selectedValue}
          onChange={handleChange}
        >
          <option value="">--Please choose an option--</option>
          <option value="brandfortunes.Flipkart2_commision">
            Flipkart commision fees
          </option>
          <option value="brandfortunes.Flipkart2_fixedFees">
            Flipkart fixed fees
          </option>
          <option value="brandfortunes.Flipkart2_ReverseShippingFees">
            Flipkart Reverse Shipping fees
          </option>
          <option value="brandfortunes.Flipkart2_ShippingFees">
            Flipkart Shipping fees
          </option>
          <option value="brandfortunes.Myntra2_Commission">
            Myntra Commission fees
          </option>
          <option value="brandfortunes.Myntra2_Fixedfees">
            Myntra Fixed fees
          </option>
          <option value="brandfortunes.Myntra2_ForwardShippingCharges">
            Myntra Forward Shipping fees
          </option>
          <option value="brandfortunes.Myntra2_ReverseShippingCharges">
            Myntra Reverse Shipping fees
          </option>
          <option value="brandfortunes.new_table">
            Test
          </option>
          {/* <option value="flipkart_fixedfees">Flipkart fixed fees</option>
          <option value="flipkart_reverseshippingfees">
            Flipkart reverse shipping fees
          </option>
          <option value="flipkart_shippingfees">Flipkart shipping fees</option>
          <option value="myntra_com">Myntra commission details</option>
          <option value="myntra_forward">Myntra forward fees</option>
          <option value="myntra_reversefees">Myntra reverse fees</option>
          <option value="testing_table">testing_table</option> */}
        </select>
        <div className="buttons-2">
          {" "}
          <button className="new">
            <span className="news">Download</span>
          </button>
          <button className="new" onClick={templateDownload}>
            <span className="news">Template</span>
          </button>
        </div>
        {/* <img src="../../images/download.png"/> */}

        {/* <p>Selected value: {selectedValue}</p> */}
        <div class="drop_box">
          <header>
            <h4>Select File here</h4>
          </header>

          <p>Files Supported: PDF, TEXT, DOC , DOCX</p>
          {/* <input
            type="file"
            // hidden
            accept=".doc,.docx,.pdf"
            id="fileID"
            // style={{ display: "none" }}
          /> */}

          <input
            type="file"
            accept=".xlsx"
            id="fileID"
            onChange={handleFileChange}
          />
          <p className="p1">
            <span>Selected file :</span> {fileName}
          </p>
        </div>
        <div className="buttons-1">
          <button class="btn" onClick={handleUpload}>
            Add File
          </button>
          <button class="btn" onClick={handleUpload1}>
            Update File
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
